<template>
  <LoadingOverlay v-if="loading===true"/>
</template>

<script setup>
import { ref } from 'vue';
import FetchWrapper from '../api.js';
import LoadingOverlay from '../components/LoadingOverlay.vue'

import { useRouter } from 'vue-router'
const $router = useRouter()

const docName = 'Diary';
const tableName = 'Diary entries';
const sourceDoc = 'N4JuJKumIt';
var docID = null;
var tableID = null;
var dateCol = null;
var notesCol = null;
var loading = ref(true);

async function copyTemplate() {
  const api = new FetchWrapper();
  let data = null;

  // Copy template doc.
  const body = {
    "title": "Diary",
    "sourceDoc": sourceDoc,
  }
  data = await api.post('docs', body)
  return data.id
}

async function setupDiary () {
  const api = new FetchWrapper();
  let data = null;

  // Check if a diary doc exists.
  data = await api.get('docs')
  const docs = data.items
  docs.forEach((doc) => {
    if (doc.name === docName) docID = doc.id
  })
  if (docID === null) {
    console.log("Copying doc because none exists.")
    docID = await copyTemplate()
  }

  // Then check doc structure.
  data =  await api.get('docs/'+docID+'/tables', 10, 1000)
  const tables = data.items
  tables.forEach((table) => {
    if (table.name === tableName) tableID = table.id
  })

  // If good, then use the doc, otherwise create a new one based on the template.
  data = await api.get('docs/'+docID+'/tables/'+tableID+'/columns')
  const columns = data.items
  columns.forEach(column => {
    if (column.name === 'Date') dateCol = column.id
    if (column.name === 'Notes') notesCol = column.id
  })
  console.log("dateCol:", dateCol)
  console.log("notesCol:", notesCol)

  // Save to local storage.
  localStorage.setItem("docID", docID);
  localStorage.setItem("tableID", tableID);
  localStorage.setItem("dateCol", dateCol);
  localStorage.setItem("notesCol", notesCol);

  // Redirect to a new entry or throw error.
  $router.replace('/entry')
  loading.value = false
}
setupDiary()
</script>