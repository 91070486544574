// You can't inject outside script setup.
// import { inject } from 'vue';\
// const $cookies = inject('$cookies');

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default class FetchWrapper {
  constructor(baseURI = 'https://coda.io/apis/v1/') {
    this.baseURI = baseURI;
    this.token = getCookie('coda_token');
    if (this.token === null) {
      throw new Error("No Coda API token");
    }
  }

  wait(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  async get(path, tries = 1, delay = 500) {
    const response = await this.call(`${this.baseURI}${path}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.token,
      },
    }, tries, delay)
    return response;
  }

  async put(path, data, tries = 1, delay = 500) {
    const response = await this.call(`${this.baseURI}${path}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Authorization': 'Bearer ' + this.token,
        'Content-Type': 'application/json',
      },
    }, tries, delay);
    return response;
  }

  async post(path, data, tries = 1, delay = 500) {
    const response = await this.call(`${this.baseURI}${path}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Authorization': 'Bearer ' + this.token,
        'Content-Type': 'application/json',
      },
    }, tries, delay);
    return response;
  }

  async delete(path, tries = 1, delay = 500) {
    const response = await this.call(`${this.baseURI}${path}`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + this.token,
      },
    }, tries, delay);
    return response;
  }

  async call(url, options = {}, tries = 1, delay = 500) {
    const response = await fetch(url, options)
      .then(response => {
        if (!response.ok) {
          let err = new Error("HTTP status code: " + response.status)
          err.response = response
          err.status = response.status
          throw err
        }
        return response.json();
      })
      .catch(err => {
        const triesLeft = tries - 1;
        if (!triesLeft) {
          throw err;
        }
        return this.wait(delay).then(() => this.call(url, options, delay, triesLeft));
      });
    return response;
  }
}