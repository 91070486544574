<template>
  <div>
    <label for="coda-token" class="block text-sm font-medium leading-6 text-gray-900">Coda API Key</label>
    <div class="relative mt-2 rounded-md shadow-sm max-w-md">
      <input type="text" name="coda-token" v-model="codaToken" @blur="validateKey" v-on:input="validateKey" class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600" placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"/>
    </div>
    <span v-if="error" class="mt-2 text-sm text-red-500">
      Coda token is not valid.
    </span>
  </div>
  <button @click="saveFuntion" :disabled="error || codaToken === ''">{{ saveText }}</button>
</template>

<script setup>
</script>

<script>
  export default {
    name: 'InputToken',
    emits: [
      'save-function'
    ],
    props: {
      saveText: String,
    },
    data() {
      return {
        codaToken: '',
        error: false,
      }
    },
    methods: {
      validateKey() {
        if (this.codaToken === "") {
          this.error = true
        } else {
          this.error = false
        }
      },
      saveFuntion() {
        this.$emit('save-function', this.codaToken)
      }
    }
  }
</script>