<template>
  <div id="app-header" class="fixed flex top-0 w-full h-20 bg-slate-100">
    <div v-if="isHomePage" class="max-w-20 px-10 place-content-center">
    </div>
    <div v-if="!isHomePage" @click="goBack" class="max-w-20 place-content-center cursor-pointer">
      <img alt="Go back" :src="require('@/assets/left-chevron.png')" class="h-full p-7">
    </div>
    <div @click="goToHome" class="max-w-20 place-content-center cursor-pointer">
      <img alt="Diary logo" :src="require('@/assets/diary.png')" class="h-full py-3">
    </div>
    <div @click="goToSettings" class="max-w-20 place-content-center cursor-pointer">
      <img alt="User settings" :src="require('@/assets/settings.png')" class="h-full p-6">
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router'
import { watch } from 'vue';

const route = useRoute()
const isHomePage = ref(route.path == '/');

watch(
  () => route.path,
  (to, from) => {
    isHomePage.value = (to == '/')
  }
)
</script>

<script>
export default {
  name: 'AppHeader',
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goToHome() {
      this.$router.push('/')
    },
    goToEntry() {
      this.$router.push('/entry')
    },
    goToSettings() {
      this.$router.push('/settings')
    },
  },
}
</script>

<style>
  #app-header {
    flex-direction: row;
    justify-content: space-between;
  }
</style>