import { createApp } from 'vue'
import App from './App.vue'
import './styles/app.css';
import './registerServiceWorker'

// import { createMemoryHistory, createRouter } from 'vue-router'
import { createWebHistory, createRouter } from 'vue-router'
import HomeView from './pages/DiaryList.vue'
import EntryView from './pages/DiaryEntry.vue'
import SettingsView from './pages/UserSettings.vue'
import OnboardingView from './pages/OnboardingUser.vue'
import SetupView from './pages/OnboardingSetup.vue'

import VueCookies from 'vue-cookies'

const routes = [
  { path: '/', component: HomeView },
  { path: '/onboarding', component: OnboardingView },
  { path: '/onboarding/setup', component: SetupView },
  { path: '/entry', component: EntryView },
  { path: '/entry/:id', component: EntryView },
  { path: '/settings', component: SettingsView },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(function (to, from, next) {
  const onboarding = Boolean(!VueCookies.get("coda_token"));
  if (onboarding && to.path !== '/onboarding') {
    next({ path: '/onboarding' })
  } else if (!onboarding && to.path === '/onboarding') {
    next({ path: '/' })
  } else {
    next()
  }
})

createApp(App)
  .use(router)
  .use(VueCookies, { sameSite: 'Strict'})
  .mount('#app')