<template>
  <div id="diary-entry" class="flex flex-col mt-24 m-10">
    <LoadingOverlay v-if="entry===null"/>
    <Datepicker v-model="date" :size="date.length" :enable-time-picker="false" :format="format" :clearable="false" position="left"/>
    <textarea v-model="input" placeholder="Dear diary..." class="w-full flex-auto overflow-auto border-black resize-none outline-none"></textarea>
    <button v-if="!saving" @click="save(date, input)">Save</button>
    <button v-if="saving" class="inline-flex items-center place-content-center" disabled="">
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      Saving...
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const $router = useRouter()
var rowID = route.params.id

// Input
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
const input = ref(null);

// Fetch the appropriate diary entry.
const entry = ref(null);
const date = ref(new Date()); // Defaults to today's date
const format = (date) => {
  return dayjs(date).format('dddd D MMM, YYYY');
}

import FetchWrapper from '../api.js';
const api = new FetchWrapper();
const docID = localStorage.getItem("docID");
const tableID = localStorage.getItem("tableID");
const dateCol = localStorage.getItem("dateCol");
const notesCol = localStorage.getItem("notesCol");

function fetchEntry(rowIdentifier) {
  console.log("Edit entry:", rowIdentifier)

  api.get('docs/'+docID+'/tables/'+tableID+'/rows/'+rowIdentifier, 10, 1000)
    .then(data => {
      entry.value = data;
      input.value = data.values[notesCol];
      date.value = new Date(data.values[dateCol]);
      console.log("Date read as:", data.values[dateCol])
      console.log("Date stored as object:", date)
    })
}

if (!rowID) {
  // Handle new diary entry.
  console.log("Creating new entry.")

  const body = {
    "rows": [{
      "cells": [{
        "column": notesCol, "value": "",
      }]
    }]
  }
  api.post('docs/'+docID+'/tables/'+tableID+'/rows', body)
    .then(data => {
      rowID = data.addedRowIds[0]; // The new rowID.
      $router.replace('/entry/' + rowID);
      fetchEntry(rowID);
    })
} else {
  // Handle existing diary entry.
  fetchEntry(rowID);
}
</script>

<script>
import dayjs from 'dayjs';
import LoadingOverlay from '../components/LoadingOverlay.vue'

export default {
  name: 'DiaryEntry',
  data() {
      return {
        saving: false,
      }
  },
  methods: {
    save(date, input) {
      console.log("Saving diary entry.")
      this.saving = true;

      // I don't know why I have to do this again...
      const api = new FetchWrapper();
      const docID = localStorage.getItem("docID");
      const tableID = localStorage.getItem("tableID");
      const dateCol = localStorage.getItem("dateCol");
      const notesCol = localStorage.getItem("notesCol");

      // Handle edit diary entry.
      const rowID = this.$route.params.id
      console.log("Date to save:", date)
      console.log("Date to save (str):", date.toISOString())
      const body = {
        "row": {
          "cells": [
            {"column": dateCol, "value": date.toISOString()},
            {"column": notesCol, "value": input},
          ]
        }
      }
      api.put('docs/'+docID+'/tables/'+tableID+'/rows/'+rowID, body)
        .then(() => {
          // Success.
          this.saving = false;
        })
    },
  },
}
</script>

<style>
  #diary-entry {
    height: calc(100vh - 8.5rem);
  }

  .dp__input {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    margin-right: 2rem;
    border: none;
    --dp-font-size: 1.5rem;
  }

  .dp__input_icon {
    /* left: auto;*/
    /* right: 0;*/
    display: none;
  }

  .dp__theme_light {
    /*
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #1976d2;
    --dp-primary-disabled-color: #6bacea;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-border-color-focus: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
    */

    /*General*/
    --dp-font-family: Avenir, Helvetica, Arial, sans-serif;
    /* --dp-border-radius: 4px; /*Configurable border-radius*/
    /* --dp-cell-border-radius: 4px; /*Specific border radius for the calendar cell*/
    /* --dp-common-transition: all 0.1s ease-in; /*Generic transition applied on buttons and calendar cells*/

    /*Sizing*/
    width: fit-content;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* --dp-button-height: 35px; /*Size for buttons in overlays*/
    /* --dp-month-year-row-height: 35px; /*Height of the month-year select row*/
    /* --dp-month-year-row-button-size: 35px; /*Specific height for the next/previous buttons*/
    /* --dp-button-icon-height: 20px; /*Icon sizing in buttons*/
    /* --dp-cell-size: 35px; /*Width and height of calendar cell*/
    /* --dp-cell-padding: 5px; /*Padding in the cell*/
    /* --dp-common-padding: 10px; /*Common padding used*/
    /* --dp-input-icon-padding: 35px; /*Padding on the left side of the input if icon is present*/
    /* --dp-input-padding: 6px 30px 6px 12px; /*Padding in the input*/
    --dp-menu-min-width: 360px; /*Adjust the min width of the menu*/
    /* --dp-action-buttons-padding: 2px 5px; /*Adjust padding for the action buttons in action row*/
    /* --dp-row-margin:  5px 0; /*Adjust the spacing between rows in the calendar*/
    /* --dp-calendar-header-cell-padding:  0.5rem; /*Adjust padding in calendar header cells*/
    /* --dp-two-calendars-spacing:  10px; /*Space between multiple calendars*/
    /* --dp-overlay-col-padding:  3px; /*Padding in the overlay column*/
    /* --dp-time-inc-dec-button-size:  32px; /*Sizing for arrow buttons in the time picker*/
    /* --dp-menu-padding: 6px 8px; /*Menu padding*/
    
    /*Font sizes*/
    /* --dp-font-size: 1rem; /*Default font-size*/
    /* --dp-preview-font-size: 0.8rem; /*Font size of the date preview in the action row*/
    /* --dp-time-font-size: 0.8rem; /*Font size in the time picker*/
    
    /*Transitions*/
    /* --dp-animation-duration: 0.1s; /*Transition duration*/
    /* --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1); /*Timing on menu appear animation*/
    /* --dp-transition-timing: ease-out; /*Timing on slide animations*/
  }
</style>