<template>
  <div id="diary-list" class="mt-24 m-10">
    <LoadingOverlay v-if="entries===null"/>
    <ul role="list" class="divide-y divide-gray-100">
      <li @click="newEntry" class="flex justify-between gap-x-6 py-5 cursor-pointer">
        <div class="flex min-w-0 gap-x-4">
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">NEW ENTRY</p>
          </div>
        </div>
        <div class="shrink-0 place-content-center">
          <p class="text-sm leading-6 text-gray-900">+</p>
        </div>
      </li>
      <li @click="editEntry(entry.id)" v-for="entry in entries" :key="entry.id" class="flex justify-between gap-x-6 py-5 cursor-pointer">
        <div class="flex min-w-0 gap-x-4">
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">{{ entry.name }}</p>
            <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ entry.values["c-fhi_HYKVME"] }}</p>
          </div>
        </div>
        <div class="shrink-0 place-content-center">
          <p class="text-sm leading-6 text-gray-900">&gt;</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const entries = ref(null);

import FetchWrapper from '../api.js';
const api = new FetchWrapper();
const docID = localStorage.getItem("docID");
const tableID = localStorage.getItem("tableID");

api.get('docs/'+docID+'/tables/'+tableID+'/rows/')
  .then(data => {
    entries.value = data.items
  })
</script>

<script>
import LoadingOverlay from '../components/LoadingOverlay.vue'

export default {
  name: 'DiaryList',
  methods: {
    newEntry() {
      this.$router.push('/entry')
    },
    editEntry(id) {
      this.$router.push('/entry/' + id)
    },
  },
}
</script>

<style>
  #diary-list {
    height: calc(100vh - 60px);
  }
</style>

