<template>
  <AppHeader v-if="!onboarding"/>
  <!-- <AppInstall/> -->
  <RouterView/>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router'
import { watch } from 'vue';

const route = useRoute()

function isOnboarding() {
  console.log("isOnboarding:", Boolean(route.path.startsWith('/onboarding')))
  return Boolean(route.path.startsWith('/onboarding'))
}
const onboarding = ref(isOnboarding());

watch(
  () => route.path,
  (to, from) => {
    onboarding.value = isOnboarding()
  }
)
</script>

<script>
import AppHeader from './components/AppHeader.vue'
// import AppInstall from './components/AppInstall.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    // AppInstall,
  },
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>