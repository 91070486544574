<template>
  <div id="user-settings" class="mt-24 m-10">
    <h1>Welcome!</h1>
    <p class="py-5">The diary app requires a Coda account. Paste your API key below:</p>
    <InputToken
      @save-function="handleSaveFunction"
      saveText="Continue"
    />
  </div>
</template>

<script setup>
import InputToken from '../components/InputToken.vue'
import { inject } from 'vue';
import { useRouter } from 'vue-router'

const $router = useRouter()
const $cookies = inject('$cookies')
const handleSaveFunction = (token) => {
  $cookies.set("coda_token", token, Infinity, null, null, true)
  $router.push('/onboarding/setup')
}
</script>

<script>
export default {
  name: 'OnboardingUser',
  data() {
    return {
      error: false,
    }
  },
}
</script>