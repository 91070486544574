<template>
  <div id="user-settings" class="mt-24 m-10">
    <h1>Settings</h1>
    <InputToken
      @save-function="handleSaveFunction"
      saveText="Save"
    />
    <span v-if="saved" class="mt-2 text-sm text-green-500">
      Saved!
    </span>
  </div> 
</template>

<script setup>
import InputToken from '../components/InputToken.vue'
import { ref, inject } from 'vue';

const saved = ref(false)
const $cookies = inject('$cookies')
const handleSaveFunction = (token) => {
  $cookies.set("coda_token", token, Infinity, null, null, true)
  saved.value = true;
}
</script>

<script>
export default {
  name: 'UserSettings',
  data() {
    return {
      codaToken: '',
      error: false,
    }
  },
}
</script>